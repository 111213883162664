import {SEARCH_AUTOCOMPLETE} from 'constants/general'

export default function getSearch({typeSearch = ''} = {}) {

    const apiURL = SEARCH_AUTOCOMPLETE
    const property = {
        method: 'POST', 
        mode: 'cors', 
        cache: 'default', 
        body: `search=${typeSearch}`,
        headers: {
            'Content-Type':'application/x-www-form-urlencoded'
        }
    }

    return fetch(apiURL, property)
    .then(res => res.json())
    .then(autocomplete => {
        const {
            cadena,
            suggestions
        } = autocomplete
        return {
            cadena,
            suggestions
        }
    })
    .catch(error => console.log('error', error))
}