import React, {useState, useEffect, useRef} from 'react'
import './style.sass'
import {Link} from 'react-router-dom'
import { encodeStorage, decodeStorage } from 'functions/storageBase64'
import InputType from 'components/Forms/InputType'
import getShipping from 'services/getShipping'

export default function ModuleNotLogin ({...props}){

    const isLoged = decodeStorage('session_token')
    const [codigoPostal, setCodigoPostal] = useState('')
    const [codigoPostalOK, setCodigoPostalOK] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [resultsCP, setResultsCP] = useState({})
    const inputCP = useRef(null)
    const urlLink = window.location.href

    useEffect(()=>{
        setCodigoPostalOK(false)
        // console.log(codigoPostalOK)
        if(codigoPostalOK){
            // console.log('true')
            getShipping({codigoPostal: codigoPostal})
            .then(setResultsCP)
        }
    },[codigoPostalOK, codigoPostal])


    useEffect(()=>{
        if(resultsCP.status){
            //console.log('result int ',resultsCP)
            encodeStorage('shipping', resultsCP)
            encodeStorage('codigoPostal', codigoPostal)
            props.returnDirection(codigoPostal)
            encodeStorage('selectId', 0)
            encodeStorage('dirPredeterminada', null)
        }else{
            //console.log('resultadoCP  ',resultsCP)
            setErrorMessage(resultsCP.error)
        }
    },[resultsCP])


    const submitSinLoginCP = evt =>{
        evt.preventDefault()
        //console.log(codigoPostal.length)
        if(codigoPostal.length === 5){
            setCodigoPostalOK(true)
        }
        if(codigoPostal.length < 5){
            setCodigoPostalOK(false)
            setErrorMessage('El Código Postal debe ser igual a 5 dígitos')
        }
        //console.log(codigoPostal)
    }

    const updateCP = evt =>{
        evt.preventDefault()
        if(evt.target.value.length < 6){
            setCodigoPostal(evt.target.value)
        }
    }
    const focusin = () =>{
        setErrorMessage('')
    }

    useEffect(()=>{
        inputCP.current.focus()
    },[])

    return(
        <form className="cpSinLogin" onSubmit={submitSinLoginCP}>
            <p className="h3">Introduce un código postal</p>
            <InputType
                type="number"
                name="cp"
                id="cp"
                placeholder="Código postal"
                change={updateCP}
                focus={focusin}
                value={codigoPostal}
                max="99999"
                errorMessage={errorMessage}
                refs={inputCP}
            />
            <button className="btn rojo">Aceptar</button>
            {!isLoged &&
                <>
                    <p>o</p>
                    <Link to={`/login?redirect=${urlLink}`} className="btn blanco">Iniciar sesión</Link>
                </>
            }
        </form>
    )
}