import { decodeStorage, encodeStorage } from 'functions/storageBase64'

const TYPES = {
    ADD_ACCESS_TOKEN: "add_access_token",
    UPDATE_ACCESS_TOKEN: "update_access_token",
    ADD_REFRESH_TOKEN: "add_refresh_token",
    UPDATE_REFRESH_TOKEN: "update_refresh_token",
    DELETE_REFRESH_TOKEN: "delete_refresh_token",
    ADD_CART_ID: "add_cart_id",
    ADD_SESSION_ID: "add_session_id",
    ADD_CART_COUNT: "add_cart_count",
    UPDATE_CART_COUNT: "update_cart_count",
    UPDATE_PRODUCT: "update_product"
}

const INITIAL_STORE = {
    user:{
        access_token: decodeStorage('access_token'),
        refresh_token: decodeStorage('refresh_token'),
        session_ID: decodeStorage('session_token')
    },
    product: {
        click_product: {
            producto: {}
        }
    },
    cart:{
        cart_ID: decodeStorage('idCarrito'),
        cart_Count: decodeStorage('total_items') ? !isNaN( Number(decodeStorage('total_items') ) ) ? decodeStorage('total_items') : 0  : 0
    }
}

const dataReducer = (state, action) => {
    switch (action.type){
        case TYPES.UPDATE_PRODUCT:
            return{
                ...state,
                product:{
                    click_product:{
                        producto: action.payload
                    }
                }
            }
        case TYPES.ADD_ACCESS_TOKEN:
            return{
                ...state,
                user:{
                    ...state.user,
                    access_token: action.payload
                }
            }
        case TYPES.ADD_REFRESH_TOKEN:
            return{
                ...state,
                user:{
                    ...state.user,
                    refresh_token: action.payload
                }
            }
        case TYPES.UPDATE_CART_COUNT:
            return{
                ...state,
                cart:{
                    ...state.cart,
                    cart_Count: action.payload
                }
            }
        default:
            return state
    }
}

export { INITIAL_STORE, TYPES }
export default dataReducer