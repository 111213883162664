import React from 'react'
//import { Route} from 'wouter'
import { Route, Redirect } from 'react-router-dom'
import { decodeStorage  } from 'functions/storageBase64'

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        decodeStorage('session_token')
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} 
        />
    )} />
)

export default PrivateRoute
