/* Storage base64 Prod */
export function encodeStorage(key, value){
    window.localStorage.setItem( window.btoa(key), window.btoa( JSON.stringify(value) ) )
}

export function decodeStorage(key){
    const response = window.localStorage.getItem( window.btoa(key) )
    try{
        return response ? JSON.parse( window.atob(response) ) : ''
    }
    catch(error){
        if(response === "ée"){
            return '' 
        }else{
            return response
        }
    }
}
export function removeStorage(key){
    window.localStorage.removeItem(key)
    window.localStorage.removeItem( window.btoa(key) )
}


/* Storage base64 Latin Prod */
export function encodeStorageLatin(key, value){
    window.localStorage.setItem( window.btoa(unescape(encodeURIComponent(key) ) ), window.btoa(unescape(encodeURIComponent( JSON.stringify(value) ) ) ) )
}

export function decodeStorageLatin(key){
    const response = window.localStorage.getItem( window.btoa(unescape(encodeURIComponent(key) ) ) )
    //const response = decodeURIComponent( unescape( window.atob(key) ) )
    try{
        return response ? JSON.parse( decodeURIComponent( escape( window.atob(response) ) )  ) : ''
    }
    catch(error){
        if(response === "ée"){
            return '' 
        }else{
            return response
        }
    }
}
export function removeStorageLatin(key){
    window.localStorage.removeItem(key)
    window.localStorage.removeItem( window.btoa(unescape(encodeURIComponent(key) ) ) )
}





/** Storage base64  Develop*/
// export function encodeStorage(key, value){
//     window.localStorage.setItem( key,  JSON.stringify(value)  )
// }

// export function decodeStorage(key){
//     const response = window.localStorage.getItem( key )
//     try{
//         return response ? JSON.parse( response ) : ''
//     }
//     catch(error){
//         if(response === "ée"){
//             return '' 
//         }else{
//             return response
//         }
//     }
// }
// export function removeStorage(key){
//     window.localStorage.removeItem(key)
// }