import React from 'react'
import ReactDOM from 'react-dom'
import './style.sass'

function Loading(props){
    const {classLoading} = props
    return(
        <div className={`modal loading ${classLoading}`}>
            <div className="contentModal">               
                <div className="spinner"></div>
            </div>
        </div>
    )
}

export default function ModalLoading (props){
    const {type} = props
    return ReactDOM.createPortal(
        <Loading classLoading={type} />,
        document.getElementById('modal-root')
    )
}