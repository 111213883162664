import { useState, useEffect} from 'react'
import { decodeStorage, encodeStorage } from 'functions/storageBase64'

export function useIsMobile(){
    //const [ userMobile, setUserMobile ] = useState()
    let mobileData
    if(decodeStorage('isMobile') || decodeStorage('isMobile') === 'desktop' || decodeStorage('isMobile') === 'movil' ){
        mobileData = decodeStorage('isMobile')
    }else{
        mobileData = null
    }
    const [ isMobile, setIsMobile ] = useState(mobileData)
    
    //Valida si es desktop o mobile
    useEffect(()=>{
        if(!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            //setUserMobile('desktop')
            setIsMobile('desktop')
            encodeStorage('isMobile','desktop')
            try{
                document.querySelector('#root').classList.add('desktopView')
            }catch(e){}
        }
        else{
            //setUserMobile('movil')
            setIsMobile('movil')
            encodeStorage('isMobile','movil')
            try{
                document.querySelector('#root').classList.add('loginHeaderMobile')
            }catch(e){}
        }
    },[])


    // useEffect(()=>{
    //     if(userMobile){
    //         setIsMobile(userMobile)
    //     }
    // },[userMobile])
    
    return{isMobile}
}