import React from 'react'

export class ErrorBoundary extends React.Component {
    constructor(props){
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
          error: error,
          errorInfo: errorInfo
        })

        window.ineum('reportError', error, {
            componentStack: errorInfo.componentStack
        });
        // You can also log error messages to an error reporting service here
    }

    render(){
        if (this.state.errorInfo) {
            // Error path
            return (
              <div className="errorCrashWhitePage">
                <h2>Se produjo un error</h2>
                <p>Puede deberse  a un error técnico que estamos intentando solucionar. Actualiza  la página</p>
                <div className="btn rojo" onClick={window.location.reload}>Volver a cargar la página</div>
                <details className="codeErrorResume" style={{ whiteSpace: 'pre-wrap' }}>
                  {this.state.error && this.state.error.toString()}
                  <br />
                  {this.state.errorInfo.componentStack}
                </details>
              </div>
            );
          }
          // Normally, just render children
          return this.props.children;
    }
}



export class ErrorBoundaryMinimal extends React.Component {
  constructor(props){
      super(props);
      this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
      // Catch errors in any components below and re-render with error message
      this.setState({
        error: error,
        errorInfo: errorInfo
      })

      window.ineum('reportError', error, {
          componentStack: errorInfo.componentStack
      });
      // You can also log error messages to an error reporting service here
    }

  render(){
      if (this.state.errorInfo) {
          // Error path
          return (
            <div className="errorCrashWhitePageMinimal">
            </div>
          );
        }
        // Normally, just render children
        return this.props.children;
  }
}