import React, { createContext, useContext, useReducer } from "react";
import dataReducer, { INITIAL_STORE } from "./storeReducer";

const GlobalContext = createContext()


export const Provider = ({ children }) => {

    const [store, dispatch] = useReducer(dataReducer, INITIAL_STORE)

    return (
        <GlobalContext.Provider
            value={[store, dispatch]}
        >
            { children }
        </GlobalContext.Provider>
    )
}

const useStore = () => useContext(GlobalContext)[0]
const useDispatch = () => useContext(GlobalContext)[1]

export { GlobalContext, useStore, useDispatch }
export default Provider