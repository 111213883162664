import React, { useState, useEffect } from 'react';
import './style.sass';
import {Link} from 'react-router-dom';
import {useStorage64} from 'hooks/useStorageBase64';
import ModuleNotLogin from 'components/BarCP/ModuleNotLogin';


export default function ContentLogin ({...props}){
    const {
        returnDirection,
        onClose
    } = props;

    const [idDir, setIdDir] = useStorage64('selectId','');
    const [sendDirections] = useStorage64('sendDirections','');
    const [, setDirPredeterminada] = useStorage64('dirPredeterminada','');
    const [, setCodigoPostal] = useStorage64('codigoPostal','');
    
    const [viewModuleCP , setViewModuleCP] = useState(true);
    const [selectDir, setSelectDir] = useState(false);
    const [dataDirection, setDataDirection] = useState({});

    const updateDataElements = () =>{
        returnDirection(dataDirection.codigoPostal);
        setIdDir(dataDirection.id);
        setDirPredeterminada({'nombre': dataDirection.nombre, 'municipio': dataDirection.municipio, 'calle': dataDirection.calle});
        setCodigoPostal(dataDirection.codigoPostal);
    };

    useEffect(()=>{
        if(selectDir){
            updateDataElements();
        }
        return setSelectDir(false);
    },[selectDir]);
    
    
    const addCP = () =>{
        setViewModuleCP(false);
    }

    const selectedDirection = (id, nombre, direccion, codigoPostal, municipio, calle) =>{
        setSelectDir(true);
        if(calle){
            setDataDirection({id:id, nombre:nombre, codigoPostal:codigoPostal, municipio:municipio, calle:calle});
        }
        else{
            setDataDirection({id:id, nombre:nombre, codigoPostal:codigoPostal, municipio:municipio, calle:direccion});
        }
        setIdDir(id);
    };

    return(
        <>
            {viewModuleCP
                ?
                    <form className="cpConLogin">
                        <div>
                            <p className="h3 elige">Elige tu dirección de entrega</p>
                            <p className="small">Los costos y tiempos de envío pueden variar según el C.P.</p>
                            
                            <div className="cardScroll">
                                <div className="contScrollCard">
                                    {sendDirections &&
                                        sendDirections.address.map(({id, nombre, direccion, ciudad, codigoPostal, municipio, calle}) =>
                                            <article key={id} className={id !== idDir ? "cardDirection cdirect" : "cardDirection active cdirect"} onClick={() => selectedDirection(id, nombre, direccion, codigoPostal, municipio, calle)}>
                                                <p className="h4">{nombre}</p>
                                                <p className="dataDirection">Calle {direccion} {ciudad}</p>
                                            </article>
                                        )
                                    }
                                    
                                    <Link to="/mi-cuenta/direcciones-envio/agregar" className="cardDirection cdirect">
                                        <p className="h4 cardD">Agregar o editar dirección de envío</p>
                                    </Link>
                                </div>
                            </div>

                        </div>
                        <p onClick={addCP} className="addCP">Introduce un código postal</p>

                    </form>
                :
                    <ModuleNotLogin returnDirection={returnDirection} onClose={onClose} />
            }
        </>
    )
}